// core-js polyfills
// https://github.com/zloirock/core-js
import 'core-js/features/array/find'
import 'core-js/features/array/find-index'
import 'core-js/features/object/assign'
import 'core-js/features/string/includes'
import './polyfills'

import MediaQueries from './global/js/mediaqueries'
import { createPopper } from '@popperjs/core'
// https://swiperjs.com
import Swiper from 'swiper/bundle'

// Add libs to the windows object
window.createPopper = createPopper
window.Swiper = Swiper

new MediaQueries(document.querySelector('body'))
