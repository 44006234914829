
class MediaQueries {
  constructor(elem) {
    this.element = elem
    this.currentViewport = this.getCurrentViewport()
    this.lastViewport = ''
    this.element.classList.add('mq')
    this.init()
    this.handleResize()
  }

  init() {
    this.handleResize = this.handleResize.bind(this)
    window.addEventListener('resize', this.throttle(this.handleResize))
  }

  destroy() {
    window.removeEventListener('resize', this.throttle(this.handleResize))
  }

  getCurrentViewport() {
    let vp = window.getComputedStyle(this.element, ':before').content
    return vp
  }

  createEvent(name, params) {
    let event
    if (typeof Event === 'function') {
      if (params && typeof CustomEvent === 'function') {
        event = new CustomEvent(name, params)
      } else {
        event = new Event(name)
      }
    } else {
      if (params) {
        event = document.createEvent('CustomEvent')
        const { bubbles, cancelable, detail } = (params || {})
        event.initCustomEvent(name, bubbles, cancelable, detail)
      } else {
        event = document.createEvent('Event')
        event.initEvent(name, true, true)
      }
    }
    return event
  }

  throttle(fn) {
    let throttle
    return function() {
      const args = arguments
      const context = this
      if (!throttle) {
        throttle = true
        setTimeout(() => {
          throttle = false
          fn.apply(context, args)
        }, 200)
      }
    }
  }

  handleResize() {
    let viewport = this.getCurrentViewport()

    // If no change to viewport nothing needs to be done
    if (viewport === this.currentViewport) return

    // Set new values
    this.lastViewport = this.currentViewport
    this.currentViewport = viewport

    let changeEvent = this.createEvent('viewportchange', {
      detail: {
        viewport: this.currentViewport,
        lastViewport: this.lastViewport,
      },
    })

    this.element.dispatchEvent(changeEvent)
  }
}

export default MediaQueries
